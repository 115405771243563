nav.side-nav {
    position: fixed;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 280px;
    min-height: 100vh;
    padding: 1rem;
    background-color: #212529;
    box-shadow: 1px 1px 15px darkgray;
    transition: margin 200ms ease-out;
    z-index: 1;
    overflow-y: auto;
    height: 100%;

    a {
        color: #fff;
    }

    ul.nav-list {
        line-height: 2;

        .nav-item a i {
            margin-right: .5rem;
        }
    }
}

.btn-show-hide-menu {
    display: none;
}

@media (max-width: 768px) {
    nav.side-nav {
        margin-left: -18rem;
    }

    nav.show-side-menu {
        margin-left: 0;
    }

    .btn-show-hide-menu {
        display: block;
        margin-left: 8px;
    }
}
